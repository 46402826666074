<template>
  <v-container fluid class="read-container pa-4 pa-sm-6 pa-md-8">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="8" xl="6">
        <v-card class="pa-4 mb-6" elevation="2">
          <v-select
            label="版本"
            :items="types"
            v-model="type"
            @change="fetchData"
            outlined
            dense
            class="mb-4"
          ></v-select>
          <v-menu
            v-model="chapterMenu"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedChapterTitle"
                label="章节"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-card>
              <v-list height="300" class="overflow-y-auto">
                <v-list-item
                  v-for="chapter in chapters"
                  :key="chapter.id"
                  @click="selectChapter(chapter)"
                >
                  <v-list-item-title>{{ chapter.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-card>
        <v-card class="pa-4 article-card" elevation="2">
          <div v-if="loading" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div v-else-if="error" class="error-message">{{ error }}</div>
          <div v-else class="article-content" v-html="article.content"></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from '@/utils/request';

export default {
  name: "Read",
  data() {
    return {
      chapters: [],
      type: '',
      chapterId: null,
      article: {},
      loading: false,
      error: null,
      chapterMenu: false,
      selectedChapterTitle: '',
    }
  },
  computed: {
    types() {
      if(this.$store.getters.isLoggedIn) {
        return '嘉靖壬午本,叶逢春本,黄正甫本,通行本,章节史料,章节注释'.split(',');
      } else {
        return '嘉靖壬午本,黄正甫本,通行本'.split(',');
      }
    }
  },
  created() {
    this.type = this.$route.params.type;
    this.chapterId = parseInt(this.$route.params.chapterId, 10) || null;
    this.fetchData();
  },
  methods: {
    async fetchChapters() {
      try {
        const response = await service.get('/wikiapi/read/list', { params: { type: this.type } });
        this.chapters = response.data;
      } catch (error) {
        console.error('获取章节列表失败:', error);
        this.error = '获取章节列表失败';
      }
    },
    async fetchArticle() {
      if (!this.chapterId) return;
      try {
        const response = await service.get(`/wikiapi/read/article/${this.chapterId}`, { params: { type: this.type } });
        this.article = response.data;
      } catch (error) {
        console.error('获取文章内容失败:', error);
        this.error = '获取文章内容失败';
      }
    },
    onChapterChange() {
      this.fetchData();
    },
    selectChapter(chapter) {
      this.chapterId = chapter.id;
      this.selectedChapterTitle = chapter.title;
      this.chapterMenu = false;
      this.onChapterChange();
    },
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        await this.fetchChapters();
        if (this.chapterId) {
          await this.fetchArticle();
          const selectedChapter = this.chapters.find(c => c.id === this.chapterId);
          this.selectedChapterTitle = selectedChapter ? selectedChapter.title : '';
        }
      } catch (error) {
        console.error('获取数据失败:', error);
        this.error = '获取数据失败';
      } finally {
        this.loading = false;
      }
      this.updateRoute();
    },
    updateRoute() {
      const { type, chapterId } = this.$route.params;
      if (type !== this.type || parseInt(chapterId, 10) !== this.chapterId) {
        this.$router.push({
          name: '阅读',
          params: { type: this.type, chapterId: this.chapterId }
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.read-container {
  background-color: #f5f5f5;
  min-height: calc(100vh - 100px);
}

.article-card {
  background-color: #ffffff;
  border-radius: 8px;
}

.article-content {
  font-size: 18px;
  line-height: 1.8;
  color: #333;
}

.article-content p {
  margin-bottom: 1.5em;
  text-indent: 2em;
}

.error-message {
  color: #ff5252;
  text-align: center;
  font-size: 16px;
}

@media (max-width: 600px) {
  .article-content {
    font-size: 16px;
  }
}

/* 添加以下样式 */
::v-deep .v-select__selections {
  max-height: none !important;
}

::v-deep .v-menu__content {
  max-height: 300px !important;
  overflow-y: auto !important;
}

::v-deep .v-list {
  padding: 0;
}

::v-deep .v-list-item {
  min-height: 36px;
}

::v-deep .v-list-item__content {
  padding: 4px 0;
}

/* 强制显示滚动条 */
::v-deep .v-menu__content::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

::v-deep .v-menu__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}
</style>

<style>
.article-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.s3 { font-weight: bold; }
.q { color: #008080; }
.h { color: #FF0000; }
.z { color: #FF00FF; }
.l { color: #000080; }
</style>
